
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































































.employee-advocacy {
  ::v-deep {
    .generosity-slider__item-inner {
      padding-top: 7.5rem;

      @include mq(l) {
        padding-top: 10rem;
      }
    }

    .generosity-slider__item__description {
      font-size: 3.6;
    }

    .services-list__list__item__icon {
      width: auto;
      height: 5rem;
      margin-bottom: 1.5rem;
    }

    .card-small__title {
      display: flex;
      flex-direction: column;
    }
  }
}

.employee-advocacy__hero {
  ::v-deep {
    .hero__slides__item-inner {
      @include mq('l') {
        align-items: flex-start;
        margin-top: 15rem;
      }
    }

    .hero__slides__item__icon {
      margin-bottom: $spacing;
      font-weight: 500;
      line-height: 1;

      .icon {
        height: 20rem;
        max-height: 20rem;

        /* stylelint-disable-next-line max-nesting-depth */
        @include mq($until: l) {
          display: none;
        }
      }
    }
  }
}
